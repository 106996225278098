<template>
<div class="main">
  <div class="component-content">
    <Loading v-if="loading"/>

    <TopMenu class="mb-12p">

      <template v-slot:page-name>管理生产单</template>

      <template v-slot:menu-block1>
        <router-link :to="{name: 'ProductionEdit'}">
          <button class="btn btn-blue">
            <span class="mr-2">新建生产单</span>
            <img class="icon-16 d-inline-block" src="/static/images/icon/add_12.svg">
          </button>
        </router-link>
      </template>
    </TopMenu>

    <SubMenu
      :labels="check"
      :options="submenu"
      @call="_call"
    >
      <template v-slot:left-menu>

        <div class="li ml-auto">
          <div class="input-group flex-1 mr-2p h-100">
            <span class="input-group-text">
              <img class="icon-16" src="/static/images/icon/search.svg">
            </span>
            <input type="text" class="form-control font-12" placeholder="搜索单号" v-model="submenu.searchNumber.value" @change="_search">
            <button class="btn btn-close" v-show="submenu.searchNumber.value" @click="_clearSearchNumber()">
              <img class="icon-12" src="/static/images/icon/close.svg">
            </button>
          </div>
        </div>

        <div class="li ml-2p">
          <div class="input-group flex-1 mr-2p h-100">
            <span class="input-group-text">
              <img class="icon-16" src="/static/images/icon/search.svg">
            </span>
            <input type="text" class="form-control font-12" placeholder="搜索工人" v-model="submenu.searchUser.value" @change="_search">
            <button class="btn btn-close" v-show="submenu.searchUser.value" @click="_clearSearchUser()">
              <img class="icon-12" src="/static/images/icon/close.svg">
            </button>
          </div>
        </div>

        <div class="li ml-2p">
          <div class="input-group flex-1 mr-2p h-100">
            <span class="input-group-text">
              <img class="icon-16" src="/static/images/icon/search.svg">
            </span>
            <input type="text" class="form-control font-12" placeholder="搜索产品" v-model="submenu.searchProduct.value" @change="_search">
            <button class="btn btn-close" v-show="submenu.searchProduct.value" @click="_clearSearchProduct()">
              <img class="icon-12" src="/static/images/icon/close.svg">
            </button>
          </div>
        </div>

      </template>
    </SubMenu>

    <div class="row flex-1 h-100 overflow-hidden">
      <div class="col-6 down-list h-100">
        <div class="content bg-25 h-100">
          <div class="d-flex">
            <label><div class="flex-list-header hr"><input name="check" type="checkbox" :checked="checkAll" @click="funCheckAll" /></div></label>
            <div class="flex-list hr w-100">
              <div style="width:30%;padding-left:12px;">工人名称</div>
              <div style="width:25%;">单号</div>
              <div style="width:25%;">日期</div>
              <div style="width:20%;">类型</div>
            </div>
          </div>

          <div
          class="d-flex hover"
          :class="{'active': active==index, 'new-data': datas.production[index].new_data && datas.production[index].new_data == true}"
          v-for="(data, index) in datas.production"
          :key="data.id"
          >
              <label><div class="flex-list-header hr"><input name="check" type="checkbox" :value="data" v-model="check"/></div></label>
              <div class="flex-list hr w-100 btn-default" :class="data.type==0 ? 'text-y' : '' " @click="showList(data, index)">
                <div style="width:30%;padding-left:12px;">{{ data.username }}</div>
                <div style="width:25%;">{{ data.number }}</div>
                <div style="width:25%;">{{ data.date_at }}</div>
                <div style="width:20%;">{{ data.type==1 ? '生产单' : '回滚单' }}</div>
              </div>
          </div>
        </div>

        <div class="pagination">
          <v-pagination
            v-model="currentPage"
            :pages="totalPage"
            active-color="#004bff"
            @update:modelValue="getDatas($event, {
              product: submenu.searchProduct.value,
              number: submenu.searchNumber.value,
              company: submenu.searchUser.value
            })"
          />
        </div>
      </div>
      <div class="col-6 h-100">
        <div class="content bg-25 h-100 p-2">
          <div v-if="list">
            <h3><span class="text-slim">工人名称</span> {{ list.username }}</h3>
            <h3><span class="text-slim">票据单号</span> {{ list.number }}</h3>
            <h3><span class="text-slim">生产日期</span> {{ list.date_at }}</h3>
            <div class="table">
                <div class="thead">
                  <div class="table-row">
                    <div class="table-cell" style="width:40%;">产品</div>
                    <div class="table-cell" style="width:10%;">规格</div>
                    <div class="table-cell" style="width:10%;">数量</div>
                    <div class="table-cell" style="width:20%;">成本单价</div>
                    <div class="table-cell" style="width:20%;">成本金额</div>
                  </div>
              </div>
              <div class="tbody">
                  <div class="table-row" v-for="product in list.production_list" :key="product">
                    <div class="table-cell" style="width:40%;">{{ product.name }}</div>
                    <div class="table-cell" style="width:10%;">{{ product.specification }}</div>
                    <div class="table-cell" style="width:10%;">{{ product.quantity }}</div>
                    <div class="table-cell" style="width:20%;">{{ $math.formatMoney($math.Div(product.total_price, product.quantity), 2) }}</div>
                    <div class="table-cell" style="width:20%;">{{ product.total_price }}</div>
                  </div>
              </div>
            </div>
            <h2><span class="text-slim">总金额</span> ￥{{ $math.formatMoney(totle(), 2) }}</h2>
            <h4><span class="text-slim">备注</span> {{ list.remarks }}</h4>
            <h4><span class="text-slim">制单人</span> {{ list.prepared_by }}</h4>
            <h4><span class="text-slim">送货司机</span> {{ list.driver }}</h4>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import SubMenu from '@/components/SubMenu.vue'

export default {
  name: 'OrderList',

  data() {
    return {
      loading: true,
      datas: [],
      list: null,
      active: null,
      check: [],
      checkAll: false,
      currentPage: 1,
      totalPage: 1,
      submenu: {
        edit: {
          disabled: 'singleDisabled',
          fetch: 'ProductionEdit',
          permissions: '602'
        },
        del: {
          fetch: '/api/production/delete',
          permissions: '604'
        },
        // copy: {
        //   fetch: '/api/production/copy',
        //   permissions: '104'
        // },
        // print: {
        //   fetch: 'ProductionPrint',
        //   permissions: '105'
        // },
        // export: {
        //   fetch: '/api/production/export',
        //   permissions: '106'
        // },
        searchProduct: {
          value: '',
        },
        searchUser: {
          value: '',
        },
        searchNumber: {
          value: '',
        }
      }
    }
  },

  mounted() {
    console.log(this.$options.name+'挂载')

    this.getDatas()
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
    SubMenu
  },

  watch: {
    check: function() {
      if (this.datas.production && this.datas.production.length > 0) {
        //全选
        if (this.check.length != this.datas.production.length) {
          this.checkAll = false
        } else {
          this.checkAll = true
        }
      }
    },

    datas: function() {
      this.checkAll = false
      this.check = []
      this.list = this.datas.production[0]
      this.active = 0
    },

  },

  computed: {
    totle() {
      let _this = this
      return () => {
        let totalPrice = 0;
        this.list.production_list.forEach(function(item) {
          totalPrice = _this.$math.Add(totalPrice, item.total_price)
        })
        return totalPrice
      }
    },
  },

  methods: {
    getDatas(page, search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let p = page ? page : 1
        _this.$axios.get('/api/production', {
          headers: {
            token: JSON.parse(sessionStorage.Authorization).token
          },
          params: {
            page: p,
            take: 20,
            search: search
          }
        })
        .then(res => {
          resolve(res);

          if (res.status == 200) {
            if (res.data) {
              _this.datas = res.data
              _this.currentPage = res.data.current_page
              _this.totalPage = res.data.total_page
              console.log('ok')
            }
            _this.loading = false
          }
        })
        .catch(error => {
          reject(error)
          console.error('error:', error)
        })
      });
    },

    showList(data, index) {
      this.active = index
      this.list = data
    },

    funCheckAll() {
      let _this = this
      this.check = []
      if (!this.checkAll && this.datas.production) {
        this.datas.production.forEach(function(item) {
          _this.check.push(item)
        })
      }
      this.checkAll = !this.checkAll
    },

    //子组件方法
    _edit() {
      if (this.check.length === 1) {
        this.$router.push({name: 'ProductionEdit', params: {id: this.check}})
      }
    },

    // 综合搜索
    _search() {
      let search = {
        product: this.submenu.searchProduct.value,
        number: this.submenu.searchNumber.value,
        user: this.submenu.searchUser.value
      }

      this.getDatas(null, search)
    },

    _clearSearchProduct() {
      this.submenu.searchProduct.value = '';
      this._search()
    },

    _clearSearchUser() {
      this.submenu.searchUser.value = '';
      this._search()
    },

    _clearSearchNumber() {
      this.submenu.searchNumber.value = '';
      this._search()
    },

    _call(data) {
      if (data.res.success) {
        let _this = this
        //let oldDatas = this.datas.production

        if (data.function == 'del') {
          this.getDatas(this.currentPage).then(res => {
            if (res.status == 200) {
              console.log(res)
              _this.datas = res.data
              if (res.data.current_page > res.data.total_page) {
                this.getDatas(res.data.total_page)
              }
            }
          })
        }

        if (data.function == 'copy') {
          this.getDatas().then(res => {
            if (res.status == 200) {
              res.data.production.forEach(function(item) {
                let newData = false;
                data.res.data.forEach(function(i) {
                  if (item.id == i) {
                    newData = true;
                    return false;
                  }
                })
                item.new_data = newData
              })
              _this.datas = res.data
            }
          })
        }
      }
    }

  },

}

</script>

<style scoped>
.active {
  background-color: #004bff !important;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col-4 {
  position: relative;
  width: 100%;
  padding-right: 1px;
  padding-left: 1px;
  -ms-flex: 0 0 33.333%;
  flex: 0 0 33.333%;
  max-width: 33.333%;
}

.col-6 {
  position: relative;
  width: 100%;
  padding-right: 1px;
  padding-left: 1px;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-6:first-child {
  padding-left: 0;
}
.col-6:last-child {
  padding-right: 0;
}
.table {
  overflow-y: auto;
}
.table::-webkit-scrollbar{
  display: none;
}

.new-data {
    background-color: #865d97;
    animation: fade 1000ms;
}

.btn-close {
  padding: 6px;
  border-radius: 100%;
}

</style>
